import React, { ReactElement } from "react";
import MainBanner from "@components/bestteethaligners/mainBanner/mainBanner";
import { GetServerSideProps } from "next";
import BestTeethAlignersWrapper from "@components/layouts/bestteethalignersWrapper";
import { getPageProps } from "@services/initial-calls";
import Advantages from "@components/bestteethaligners/advantages";
import WhyUs from "@components/bestteethaligners/whyUs";
import dynamic from "next/dynamic";
import { useDomainContext } from "@hooks/useDomainContext";
const OffersList = dynamic(() => import("@components/shared/offersList"));
export default function Home(): ReactElement {
    const { domain } = useDomainContext();
    return (
        <BestTeethAlignersWrapper>
            <MainBanner />
            <section>
                <OffersList offers={domain?.defaultOffers} />
            </section>
            <Advantages />
            <WhyUs />
        </BestTeethAlignersWrapper>
    );
}

export const getServerSideProps: GetServerSideProps = async (context) => {
    return await getPageProps(context, "home");
};
